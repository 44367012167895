* {
  box-sizing: border-box;
  text-align: left;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.row{
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.padding {
  padding: 10px;
}

.button-container {
  margin: 3px;
} 

.full-width {
  width: 100%;
}